import React from 'react';
import { portfolioItems } from './portfolio-elements';
import { ReactComponent as ReactLogoPortfolio } from '../../img/LogoPortfolio.svg';

const Portfolio: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <section id="portfolio">
      <div className="content-box-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow slideInLeft">
              <div className="vertical-heading">
                <h5>nuestro trabajo</h5>
                <h2>Ejemplo de <br />nuestros <strong>proyectos</strong></h2>
              </div>
            </div>
            <div className="col-md-12">
              <div id="isotope-filters">
                <button className="btn active" data-filter="*"><span>Todos</span></button>
                <button className="btn" data-filter=".viviendas"><span>Viviendas</span></button>
                <button className="btn" data-filter=".cielorrasos"><span>Cielorrasos</span></button>
                <button className="btn" data-filter=".tabiques"><span>Tabiques</span></button>
                <button className="btn" data-filter=".revestimientos"><span>Revestimientos</span></button>
                <button className="btn" data-filter=".fachadas"><span>Fachadas</span></button>
              </div>
            </div>
          </div>
        </div>
        <section id="portfolio-wrapper" className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
          <div className="container-fluid">
            <div className="row no-gutters">
              <div id="isotope-container">
                {portfolioItems.map((item, index) => (
                  <div key={index} className={`col-md-3 col-sm-6 co-xs-12 ${item.classes}`}>
                    <div className="portfolio-item">
                      <a href={item.img} title={item.title}>
                        <img src={item.img} className="img-responsive" alt={item.alt} />
                        <div className="portfolio-item-overlay">
                          <div className="portfolio-item-details text-center">
                            <h3>Alejando Ojeda</h3>
                            <h5>Copyright &copy; {currentYear}</h5>
                            <div><ReactLogoPortfolio /></div>
                            <p>Construccion en Seco</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Portfolio;
