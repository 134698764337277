import React from 'react';
import serviceTabImg01 from '../img/services/service-tab-img-01.jpg';
import serviceTabImg02 from '../img/services/service-tab-img-02.jpg';
import serviceTabImg03 from '../img/services/service-tab-img-03.jpg';

const Services: React.FC = () => {
  return (
    <section id="services">
      {/* Services 01 */}
      <div id="services-01">
        <div className="content-box-lg-services">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center wow slideInDown" id="services-01-horizontal">
                <div className="horizontal-heading">
                  <h5>Qué Hacemos</h5>
                  <h2>Nuestros Servicios</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services 02 */}
      <div id="services-02">
        <div className="content-box-md-services">
          <div id="services-tabs">
            <ul className="text-center">
              <li><a href="#service-tab-1">Steel Framing</a></li>
              <li><a href="#service-tab-2">Cielorrasos y divisorias</a></li>
              <li><a href="#service-tab-3">Revestimientos y fachadas</a></li>
            </ul>

            {/* Service Tab 01 */}
            <div id="service-tab-1" className="service-tab">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <img src={serviceTabImg01} alt="Steel Framing" />
                  </div>
                  <div className="col-md-6">
                    <div className="tab-bg">
                      <h3>Viviendas en Steel Framing</h3>
                      <p>Tenemos experiencia y ejecutamos proyectos de construccion de viviendas con el sistema “Steel framing”. Tanto por su flexibilidad, rapidez de ejecución, eficiencia energética, resistencia sísmica y al fuego, aislamiento acústico o durabilidad entre otros beneficios, promovemos la construcción de viviendas con este sistema. Proveemos los servicios y materiales para la construcción del esqueleto estructural de perfiles de acero galvanizado y el sistema multicapa de aislaciones y revestimiento interior y exterior. Sea que usted necesite un proyecto llave en mano o una implementación parcial, póngase en contacto con nosotros. Le daremos una respuesta a la medida de su necesidad.</p>
                      <div id="services-02-btn-01">
                        <a className="btn btn-general btn-yellow smoth-scroll" href="#contact" title="Contáctenos" role="button">Contáctenos</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Service Tab 02 */}
            <div id="service-tab-2" className="service-tab">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <img src={serviceTabImg02} alt="Cielorrasos y divisorias" />
                  </div>
                  <div className="col-md-6">
                    <div className="tab-bg">
                      <h3>Cielorrasos y tabiques divisorios</h3>
                      <p>Según el diseño y destino, sea para viviendas, locales comerciales, supermercados, hoteles o estaciones de servicio, proveemos servicios de mano de obra con opción de provisión de materiales. Tenemos amplia experiencia en la construcción de cielorrasos con placas de yeso junta tomada, de PVC, de madera, metálicos o desmontables entre otros. Construímos tabiques divisorios, con o sin aislaciones en su interior.</p>
                      <div id="services-02-btn-02">
                        <a className="btn btn-general btn-yellow smoth-scroll" href="#contact" title="Contáctenos" role="button">Contáctenos</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Service Tab 03 */}
            <div id="service-tab-3" className="service-tab">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <img src={serviceTabImg03} alt="Revestimientos y fachadas" />
                  </div>
                  <div className="col-md-6">
                    <div className="tab-bg">
                      <h3>Revestimientos, aislaciones y fachadas</h3>
                      <p>Tenemos amplia experiencia en la ejecución de revestimientos de paredes de humedad o aislamiento de ambientes desde el interior o exterior. Ofrecemos el servicio de mano de obra para la ejecución de revestimientos con placa de yeso, siding ceméntico, de PVC, entre otros, con la opción de provisión de materiales. Ofrecemos flexibilidad en modelos constructivos con diferentes materiales como: PVC, roca de yeso y cemento, siempre de acuerdo a los requerimientos del cliente.</p>
                      <div id="services-02-btn-03">
                        <a className="btn btn-general btn-yellow smoth-scroll" href="#contact" title="Contáctenos" role="button">Contáctenos</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services 02 ends */}
    </section>
  );
};

export default Services;
