import React from 'react';

const footerData = {
    year: new Date().getFullYear(),
    text: "Todos los Derechos Reservados por",
    companyName: "Alejandro Ojeda Construcciones"
  };
  

const Footer: React.FC = () => {
  return (
    <footer className="text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p>
              Copyright &copy; {footerData.year} Todos los Derechos Reservados por <span>{footerData.companyName}</span>
            </p>
          </div>
        </div>
      </div>
      {/* Back to top button */}
      <a href="#home" id="back-to-top" className="btn bt-sm btn-yellow btn-back-to-top smoth-scroll hidden-sm hidden-xs" title="home" role="button">
        <i className="fa fa-angle-up"></i>
      </a>
    </footer>
  );
};

export default Footer;
