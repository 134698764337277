import clientImg1 from '../../img/testimonial/client-1.jpg';
import clientImg2 from '../../img/testimonial/client-2.jpg';
import clientImg3 from '../../img/testimonial/client-3.jpg';

export const testimonialItems = [
  {
    title: "Superando expectativas",
    paragraph: "\"Fundación Leven agradece a la empresa Alejandro Ojeda Construcciones, quien nos asesoró y llevó a cabo el trabajo de nuestro Centro de Rehabilitación. Como en todo proyecto, surgen variantes que fueron asesoradas, guiadas y resueltas por el equipo de trabajo de Alejandro, logrando superar nuestras expectativas, habiendo concluído su trabajo en tiempo y forma convenidos, con el resultado esperado!!!!!\"",
    img: clientImg1,
    authorName: "Fundación Leven",
    authorDetails: "Cerrillos - Salta",
    alt: "cliente"
  },
  {
    title: "Hace 15 años que los elijo",
    paragraph: "\"Hace 15 años que elijo y cuento con los servicios del Ing. Alejandro Ojeda y su empresa en la concreción de mis proyectos, debido a su profesionalismo para encarar las obras y encontrar soluciones necesarias en el desarrollo del proceso constructivo, y la conformidad total de los clientes tanto en el uso de materiales, cumplimiento de los tiempos y en los presupuestos de los trabajos.\"",
    img: clientImg2,
    authorName: "Fabiana Córdoba",
    authorDetails: "Arquitecta",
    alt: "cliente"
  },
  {
    title: "Superando desafios!",
    paragraph: "\"El equipo de Alejandro Ojeda se ha destacado por su responsabilidad y atención a nuestras especificaciones y la de nuestros clientes. Trabajando en conjunto hemos logrado superar los desafíos de este proyecto en particular. Como en la mayoría de los casos, los tiempos de obra han resultado desafiantes, pero estamos muy conformes por las respuestas obtenidas y la entrega final. Muchas gracias!\"",
    img: clientImg3,
    authorName: "Silvia Montero",
    authorDetails: "Arquitecta",
    alt: "cliente"
  }
];
