import React from 'react';
import { statsItems } from './stats-details';

const Stats: React.FC = () => {
  return (
    <section id="stats">
      <div className="content-box-md">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow slideInLeft">
              <div className="vertical-heading">
                <h5>Nuestros Números</h5>
                <h2><strong>Trayectoria</strong><br />en Construcción en Seco</h2>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp" data-wow-duration="2s">
            {statsItems.map((item, index) => (
              <div key={index} className="col-md-3 col-sm-3 col-xs-6">
                <div className="stats-item text-center">
                  <i className={item.icon}></i>
                  <h3 className={item.itemH3Class}>{item.value}</h3>
                  <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;
