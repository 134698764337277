const contactDetails = {
    heading: {
      subHeading: 'Estamos Cerca',
      mainHeading: 'Póngase en <strong>Contacto</strong> <br> con nosotros',
    },
    office: {
      city: 'Salta - Argentina',
      phone: '+54 (387) 4220588',
      email: 'tecnica@ojedaconstrucciones.com',
      address: 'Rivadavia 1319 <br>4400 - Salta, Argentina.',
    },
    socialLinks: [
      { href: '#', icon: 'fa fa-facebook' },
      { href: '#', icon: 'fa fa-twitter' },
      { href: '#', icon: 'fa fa-google-plus' },
    ],
    form: {
      title: 'Envienos un mensaje',
      description: 'Por favor, complete este formulario con sus datos y su consulta. Nos pondremos en contacto a la brevedad.',
      fields: [
        { name: 'nombre', type: 'text', id: 'name', placeholder: 'Nombre' },
        { name: 'email', type: 'email', id: 'email', placeholder: 'Dirección de email' },
        { name: 'telefono', type: 'tel', id: 'telefono', placeholder: 'Teléfono: (Cod)-(15)-XXXXXXXX' },
        { name: 'tema', type: 'text', id: 'tema', placeholder: 'Tema' },
      ],
      message: { name: 'mensaje', id: 'message', placeholder: 'Mensaje' },
      recaptcha: { sitekey: '6LcN8WcUAAAAAGvddQduxmW5Yjf237aKF3m1CFDB', callback: '' },
      privacy: { text: 'He leído y acepto las condiciones de', link: { href: 'privacidad.html', text: 'Política de Privacidad' } },
      submit: 'Enviar',
    },
  };
  
  export default contactDetails;
  