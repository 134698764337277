import React from 'react';
import { clientItems } from './clients-details';


const Clients: React.FC = () => {
  return (
    <section id="clients">
      <div className="content-box-sm">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="horizontal-heading">
                <h5>Clientes Satisfechos</h5>
                <h2>Nuestros grandes <strong> Clientes </strong></h2>
              </div>
            </div>
            <div className="col-md-12 text-center">
              <p>Cumpliendo siempre con los objetivos de entregar obras con garantía de calidad, buenas terminaciones y en los plazos acordados, la empresa Alejandro Ojeda Construcciones ha ejecutado proyectos para reconocidas empresas constructoras, destacando entre otras: RAC, Moncho, Riva, Banchick, Romero – Igarzabal, Discon, Las Cortes, San Patricio, B&V, Made, Maja y Pucheta.</p>
              <p> Nuestro profesionalismo y competitividad nos ha permitido participar en obras locales de distinguidos clientes en Salta, nombrando entre otros: Tienda San Juan, Fravega, Garbarino, Horacio Pusseto, Paseo Libertad y Swiss Medical. Nuestro trabajo y experiencia incluye también obras en estaciones de servicio, como Magneto, Florencia y Servisur, asi como en las fundaciones Anpuy y Leven.</p>
            </div>
          </div>
          <div className="row wow bounceInLeft" data-wow-duration="1s" data-wow-delay=".5s">
            <div className="col-md-12">
              <div id="client-list" className="owl-carousel owl-theme">
                {clientItems.map((item, index) => (
                  <div key={index} className="cliente">
                    <img src={item.img} className="img-responsive" alt={item.alt} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
