import { iContactForm, iFormErrors } from "standardTypes";

export const ValidateFormField = (field: string, value: string): iFormErrors => {
    let errors: iFormErrors = {};
    switch (field) {
        case 'nombre': {
            if (value.length <= 5) {
                errors = {
                    [field]: ' "nombre" debe tener mas de 5 caracteres.'
                }
            } else if (value.length > 50) {
                errors = {
                    [field]: ' "nombre" debe tener menos de 50 caracteres.'
                }
            }
            break;
        }
        case 'tema': {
            if (value.length <= 5) {
                errors = {
                    [field]: ' "tema" debe tener mas de 5 caracteres.'
                }
            } else if (value.length > 50) {
                errors = {
                    [field]: ' "tema" debe tener menos de 50 caracteres.'
                }
            }
            break;
        }
        case 'telefono': {
            let reg = new RegExp(/^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/).test(value)
            if (!reg) {
                errors = {
                    [field]: '(Codigo de area + numero: 387 4220588)'
                }
            };
            break;
        }
        case 'email': {
            let reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
            if (!reg) {
                errors = {
                    [field]: 'Ingrese una direccion de email valida.'
                }
            };
            break;
        }
        case 'mensaje':
            if (value.length <= 5) {
                errors = { [field]: ' "mensaje" debe tener más de 5 caracteres.' };
            }
            break;
        case 'privacyCheck':
            if (value !== 'check') {
                errors = { [field]: 'Debe aceptar la política de privacidad.' };
            }
            break;
        default:
            break;
    }
    return errors
}

export const ValidateForm = (formObject: iContactForm): iFormErrors[] => {
    const errors: iFormErrors[] = [];
    Object.keys(formObject).forEach((key) => {
        const validated = ValidateFormField(key, formObject[key]);
        if (Object.keys(validated).length) { errors.push(validated) }
    })
    return errors
}

export const getError = (errors: iFormErrors[], field: string): { isError: boolean, textHelper: string } => {
    const isError = !!errors.filter((error) => error[field]).length
    const errorMessage = errors.filter((error) => error[field])[0]
    const textHelper = isError && errorMessage ? errorMessage[field] : ''
    /* 
        console.log('getError');
        console.log('errors', errors)
        console.log('field', field)
        console.log('errorMessage', errorMessage)
        console.log('isError', isError);
        console.log('textHelper', textHelper); */

    return {
        isError,
        textHelper,
    }
}

export default ValidateFormField