export const statsItems = [
    {
      icon: "fa fa-bar-chart",
      value: 20,
      description: "Años de <br> Experiencia",
      itemH3Class: "counter"
    },
    {
      icon: "fa fa-map-o",
      value: 185,
      description: "Proyectos <br> ejecutados",
      itemH3Class: "counter"
    },
    {
      icon: "fa fa-cubes",
      value: 165000,
      description: "m2 <br> Construídos",
      itemH3Class: "counter m2"
    },
    {
      icon: "fa fa-users",
      value: 120,
      description: "Clientes <br> Nacionales",
      itemH3Class: "counter"
    }
  ];
  