import React, { useState, useEffect } from 'react';
import contactDetails from './contact-details';
import { ValidateForm, ValidateFormField } from '../shared/validate-form-field';
import { iContactForm, iFormErrors } from 'standardTypes';

const emptyFormObject = {
  nombre: '',
  email: '',
  telefono: '',
  tema: '',
  mensaje: '',
  privacyCheck: 'uncheck',
};

const Contact: React.FC = () => {
  const [formObject, setFormObject] = useState<iContactForm>(emptyFormObject);
  const [errors, setErrors] = useState<iFormErrors[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = event.target;
    const fieldValue = type === 'checkbox' ? (event.target as HTMLInputElement).checked ? 'check' : 'uncheck' : value;
    setFormObject({ ...formObject, [name]: fieldValue });

    const fieldError = ValidateFormField(name, fieldValue);
    const updatedErrors = errors.filter(error => !error[name]);
    if (Object.keys(fieldError).length) {
      updatedErrors.push(fieldError);
    }
    setErrors(updatedErrors);
  };

  useEffect(() => {
    const allEmptyFields = Object.keys(formObject).map((key) => formObject[key] === '')
      .every((result) => result === true);
    if (!allEmptyFields) {
      //setErrors(ValidateForm(formObject));
    }
  }, [formObject]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const validationErrors = ValidateForm(formObject);
    if (validationErrors.length === 0) {
      alert(`Form submitted: ${JSON.stringify(formObject, null, 2)}`);
    } else {
      setErrors(validationErrors);
    }
  };

  const getError = (field: string): iFormErrors | undefined => {
    return errors.find(error => error[field]);
  };

  return (
    <section id="contact">
      <div className="content-box-md">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {/* Left Side Contact Section */}
              <div id="contact-left">
                <div className="vertical-heading">
                  <h5>{contactDetails.heading.subHeading}</h5>
                  <h2 dangerouslySetInnerHTML={{ __html: contactDetails.heading.mainHeading }}></h2>
                </div>
                <div id="office">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="office">
                        <h4>{contactDetails.office.city}</h4>
                        <ul className="office-details">
                          <li><i className="fa fa-mobile"></i><span>{contactDetails.office.phone}</span></li>
                          <li><i className="fa fa-envelope"></i><span>{contactDetails.office.email}</span></li>
                          <li><i className="fa fa-map-marker"></i><span dangerouslySetInnerHTML={{ __html: contactDetails.office.address }}></span></li>
                        </ul>
                      </div>
                      <ul className="social-list">
                        {contactDetails.socialLinks.map((link, index) => (
                          <li key={index}><a href={link.href}><i className={link.icon}></i></a></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {/* Contact Right */}
              <div id="contact-right">
                <form id="contact-form" onSubmit={handleSubmit}>
                  <h4>{contactDetails.form.title}</h4>
                  <p>{contactDetails.form.description}</p>
                  <div className="row">
                    {contactDetails.form.fields.map((field, index) => (
                      <div key={index} className="col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            name={field.name}
                            type={field.type}
                            className={`form-control ${getError(field.name) ? 'is-invalid' : ''}`}
                            id={field.id}
                            placeholder={field.placeholder}
                            value={formObject[field.name as keyof typeof formObject] as string}
                            onChange={handleChange}
                          />
                          {getError(field.name) && <div className="invalid-feedback">{getError(field.name)?.[field.name]}</div>}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="form-group">
                    <textarea
                      name={contactDetails.form.message.name}
                      className={`form-control ${getError('mensaje') ? 'is-invalid' : ''}`}
                      id={contactDetails.form.message.id}
                      placeholder={contactDetails.form.message.placeholder}
                      value={formObject.mensaje}
                      onChange={handleChange}
                    ></textarea>
                    {getError('mensaje') && <div className="invalid-feedback">{getError('mensaje')?.mensaje}</div>}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="g-recaptcha" data-sitekey={contactDetails.form.recaptcha.sitekey} data-callback={contactDetails.form.recaptcha.callback}></div>
                      <input type="hidden" className="hiddenRecaptcha required" name="hiddenRecaptcha" id="hiddenRecaptcha" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-7 col-sm-7">
                      <div className="form-group" id="check-form">
                        <input
                          name="privacyCheck"
                          type="checkbox"
                          checked={formObject.privacyCheck === 'check'}
                          onChange={handleChange}
                        /> {contactDetails.form.privacy.text} <a href={contactDetails.form.privacy.link.href} target="_blank" rel="noopener noreferrer">{contactDetails.form.privacy.link.text}</a>
                        {getError('privacyCheck') && <div className="invalid-feedback">{getError('privacyCheck')?.privacyCheck}</div>}
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-5">
                      <div id="submit-btn">
                        <input id="enviar-btn" className="btn btn-general btn-yellow" type="submit" value={contactDetails.form.submit} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
