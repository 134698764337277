import React from 'react';
import team1 from '../img/team/team-1.jpg';
import team2 from '../img/team/team-2.jpg';
import team3 from '../img/team/team-3.jpg';
import team4 from '../img/team/team-4.jpg';
import team5 from '../img/team/team-5.jpg';
import team6 from '../img/team/team-6.jpg';

const Team: React.FC = () => {
  return (
    <section id="team">
      <div className="content-box-lg">
        <div className="container">
          {/* Team Members */}
          <div className="row">
            {/* Team Left Side */}
            <div className="col-md-6 col-sm-6 wow slideInLeft" data-wow-duration="1s">
              <div id="team-left">
                <div className="vertical-heading">
                  <h5>Quiénes Somos</h5>
                  <h2>
                    Un <strong>equipo</strong> con sólida
                    <br />
                    <strong>experiencia </strong>
                  </h2>
                </div>
                <p>
                  Desde su creación, nuestra empresa está enfocada en perfeccionar su tecnología, métodos y procesos para optimizar la entrega de valor cada vez que un cliente deposita su confianza en nuestro equipo.
                </p>
                <p>
                  Es así como nuestro equipo trabaja a diario en la misión de entregar obras con garantía de calidad y buenas terminaciones, en los plazos acordados con nuestros clientes pues creemos que su confianza es nuestro valor más preciado.
                </p>
              </div>
            </div>

            {/* Team Right Side */}
            <div className="col-md-6 col-sm-6 wow slideInRight" data-wow-duration="1s">
              <div id="team-members" className="owl-carousel owl-theme">
                {/* Member 01 */}
                <div className="team-member">
                  <img src={team1} alt="equipo" className="img-responsive" />
                </div>
                {/* Member 02 */}
                <div className="team-member">
                  <img src={team2} alt="equipo" className="img-responsive" />
                </div>
                {/* Member 03 */}
                <div className="team-member">
                  <img src={team3} alt="equipo" className="img-responsive" />
                </div>
                {/* Member 04 */}
                <div className="team-member">
                  <img src={team4} alt="equipo" className="img-responsive" />
                </div>
                {/* Member 05 */}
                <div className="team-member">
                  <img src={team5} alt="equipo" className="img-responsive" />
                </div>
                {/* Member 06 */}
                <div className="team-member">
                  <img src={team6} alt="equipo" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>

          <div id="progress-elements" hidden>
            {/* Progress Bars */}
            <div className="row wow fadeInUp">
              <div className="col-md-6 col-sm-6">
                {/* Skill 01 */}
                <div className="skill">
                  <h4>Cielorasos y <br />Tabiques divisorios</h4>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: '95%' }} aria-valuenow={95} aria-valuemin={0} aria-valuemax={100}>
                      <span>95%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                {/* Skill 02 */}
                <div className="skill">
                  <h4>Viviendas en <br />Steel Framing</h4>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: '65%' }} aria-valuenow={65} aria-valuemin={0} aria-valuemax={100}>
                      <span>65%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                {/* Skill 03 */}
                <div className="skill">
                  <h4>Revestimientos con <br />placas de roca y yeso</h4>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: '75%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}>
                      <span>75%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                {/* Skill 04 */}
                <div className="skill">
                  <h4>Aislaciones y <br /> fachadas</h4>
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: '90%' }} aria-valuenow={90} aria-valuemin={0} aria-valuemax={100}>
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
