import React from 'react';
import aboutImage from '../img/about/about.jpg';

const About: React.FC = () => {
  return (
    <section id="about">
      {/* About 01 */}
      <div id="about-01">
        <div className="content-box-lg">
          <div className="container">
            <div className="row">
              {/* About Left Side */}
              <div className="col-md-5 col-sm-5 wow slideInLeft" data-wow-duration="1s">
                <div id="about-left">
                  <div className="vertical-heading">
                    <h5>Quiénes Somos</h5>
                    <h2>
                      Una <strong>Trayectoria</strong>
                      <br />
                      en Construcción
                      <br />
                      en Seco
                    </h2>
                  </div>
                </div>
              </div>

              {/* About Right Side */}
              <div className="col-md-7 col-sm-7 wow slideInRight" data-wow-duration="1s">
                <div id="about-right">
                  <p>
                    <span>15 años de experiencia </span>
                    en la industria nacional de la construcción fueron el respaldo para el nacimiento de la empresa Alejandro Ojeda Construcciones. Fue asi como en el año 2012 y con el liderazgo del Ingeniero Alejandro Ojeda, un pequeño grupo de profesionales y expertos de la construcción iniciamos la actividad de nuestra empresa construyendo cielorrasos y tabiques divisorios.
                  </p>
                  <p>
                    Creciendo al ritmo de una demanda sostenida de proyectos ejecutados para familias, además de pequeñas y grandes empresas, hoy nos especializamos en todo el espectro de la construcción en seco con un gran foco en el desarrollo de viviendas de Steel Framing.
                  </p>
                </div>
              </div>
            </div>

            {/* About bottom */}
            <div className="row">
              <div className="col-md-12 wow fadeInUp" data-wow-duration="2s">
                <div id="about-bottom">
                  <img src={aboutImage} alt="Quienes Somos" className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About 01 End */}

      {/* About 02 */}
      <div id="about-02">
        <div className="content-box-md">
          <div className="container">
            <div className="row">
              {/* About item 01 */}
              <div className="col-md-4 col-sm-4 wow fadeInLeft">
                <div className="about-item text-center">
                  <i className="fa fa-rocket"></i>
                  <h3>Misión</h3>
                  <hr />
                  <p>
                    Diariamente desarrollamos y aplicamos nuestro talento y experiencia para ejecutar y entregar obras con garantía de calidad y buenas terminaciones, haciendo todo a nuestro alcance para brindar soluciones y así cumplir con nuestro compromiso.
                  </p>
                </div>
              </div>

              {/* About item 02 */}
              <div className="col-md-4 col-sm-4 wow fadeInUp" data-wow-duration="2s">
                <div className="about-item text-center">
                  <i className="fa fa-eye"></i>
                  <h3>Visión</h3>
                  <hr />
                  <p>
                    Construímos soluciones y el futuro en la región con sistemas de rápida implementación, alta calidad y eficiencia energética. Construimos confianza y honramos nuestros compromisos, siendo la primera opción en la mente y corazón de nuestros clientes.
                  </p>
                </div>
              </div>

              {/* About item 03 */}
              <div className="col-md-4 col-sm-4 wow fadeInRight">
                <div className="about-item text-center">
                  <i className="fa fa-pencil"></i>
                  <h3>Pasión</h3>
                  <hr />
                  <p>
                    Nos apasiona el desafío de llevar adelante tu proyecto y acercar las nuevas tecnologías de la construcción a tus necesidades. Nos apasiona cumplir con nuestra palabra entregando así la mejor respuesta a tu confianza e inversión económica.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About 02 Ends */}
    </section>
  );
};

export default About;
