import React from 'react';
import { testimonialItems } from './testimonial-details';

const Testimonials: React.FC = () => {
  return (
    <section id="testimonials">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-4 wow slideInLeft">
            <div className="vertical-heading">
              <h5>Nuestro Trabajo</h5>
              <h2>Qué dicen <br /> nuestros <strong>Clientes</strong></h2>
            </div>
          </div>
          <div className="col-md-8 col-sm-8 wow fadeIn" data-wow-duration="2s">
            <div id="testimonial-slider" className="owl-carousel owl-theme">
              {testimonialItems.map((item, index) => (
                <div key={index} className="testimonial">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <h3>{item.title}</h3>
                    </div>
                    <div className="col-md-6 col-sm-6 col-xs-6">
                      <div className="stars text-right">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                    </div>
                  </div>
                  <p>{item.paragraph}</p>
                  <div className="author">
                    <div className="row">
                      <div className="col-md-2 col-sm-3 col-xs-6">
                        <img src={item.img} alt={item.alt} className="img-responsive img-circle" />
                      </div>
                      <div className="col-md-10 col-sm-9 col-xs-6">
                        <div className="author-name-des">
                          <p>{item.authorName}</p>
                          <p>{item.authorDetails}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
