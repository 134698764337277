import React, { useState } from 'react';
import { ReactComponent as ReactLogo } from '../img/ojeda_logo.svg';

const Header: React.FC = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const openMobileNav = () => {
    console.log('open mobile app')
    setIsMobileNavOpen(true);
  };

  const closeMobileNav = () => {
    setIsMobileNavOpen(false);
  };

  return (
    <header>
      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="site-nav-wrapper">
            <div className="navbar-header">
              {/* Mobile menu Open Button with symbol */}
              <span id="mobile-nav-open-btn" onClick={openMobileNav}>&#9776;</span>

              {/* Logo */}
              <a href="#home" className="navbar-brand smoth-scroll">
                <div id="rgbLogo">
                  <ReactLogo />
                </div>

                <div id="wireLogo">
                  <svg
                    width="105.83"
                    height="52.917"
                    viewBox="8.085 10.142 105.833 52.917"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path
                        d="m34.543 63.059-26.458-13.229 26.458-13.229 26.458 13.229z"
                        fill="none"
                        stroke="#fff"
                        strokeWidth=".5"
                      />
                      <path
                        d="m34.543 10.142v26.458l-26.458 13.229v-26.458z"
                        fill="none"
                        stroke="#fff"
                        strokeWidth=".5"
                      />
                      <path
                        d="m87.46 10.142v26.458l26.458 13.229v-26.458z"
                        fill="none"
                        stroke="#fff"
                        strokeWidth=".5"
                      />
                      <path
                        d="m87.46 63.059-26.458-13.229 26.458-13.229 26.458 13.229z"
                        fill="none"
                        stroke="#fff"
                        strokeWidth=".5"
                      />
                      <path
                        d="m8.0846 23.372v26.458l26.458 13.229v-26.458z"
                        fill="none"
                        opacity=".95"
                        stroke="#fff"
                        strokeWidth=".5"
                      />
                      <path
                        d="m61.001 23.372v26.458l-26.458 13.229v-26.458z"
                        fill="none"
                        opacity=".9"
                        stroke="#fff"
                        strokeWidth=".5"
                      />
                      <path
                        d="m61.001 23.372v26.458l26.458 13.229v-26.458z"
                        fill="none"
                        opacity=".9"
                        stroke="#fff"
                        strokeWidth=".5"
                      />
                      <path
                        d="m113.92 23.372v26.458l-26.458 13.229v-26.458z"
                        fill="none"
                        opacity=".95"
                        stroke="#fff"
                        strokeWidth=".5"
                      />
                      <path
                        d="m57.609 29.817-19.363 9.7524-0.04703 17.101 3.4851-1.7425v-6.4549l12.437-6.2187-0.03256 6.4714 3.4256-1.7131zm-3.5202 5.1754-0.01705 3.9739-12.426 6.2296 0.0052-3.9848z"
                        fill="#fff"
                      />
                      <path
                        d="m66.293 45.861v-13.229l15.875 7.9375v13.229z"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="3.4396"
                      />
                    </g>
                  </svg>
                </div>
              </a>
            </div>

            {/* Main Menu */}
            <div className="container">
              <div className="collapse navbar-collapse">
                <ul className="nav navbar-nav pull-right">
                  <li><a className="smoth-scroll" href="#home">Inicio</a></li>
                  <li><a className="smoth-scroll" href="#about">Nosotros</a></li>
                  <li><a className="smoth-scroll" href="#team">Equipo</a></li>
                  <li><a className="smoth-scroll" href="#services">Servicios</a></li>
                  <li><a className="smoth-scroll" href="#portfolio">Proyectos</a></li>
                  <li><a className="smoth-scroll" href="#contact">Contacto</a></li>
                </ul>
              </div>
            </div>

            {/* Mobile menu options */}
            {isMobileNavOpen && (
              <div id="mobile-nav">
                {/* Mobile menu close button */}
                <span id="mobile-nav-close-btn" onClick={closeMobileNav}>&times;</span>

                <div id="mobile-nav-content">
                  <ul className="nav">
                    <li><a className="smoth-scroll" href="#home" onClick={closeMobileNav}>Inicio</a></li>
                    <li><a className="smoth-scroll" href="#about" onClick={closeMobileNav}>Nosotros</a></li>
                    <li><a className="smoth-scroll" href="#team" onClick={closeMobileNav}>Equipo</a></li>
                    <li><a className="smoth-scroll" href="#services" onClick={closeMobileNav}>Servicios</a></li>
                    <li><a className="smoth-scroll" href="#portfolio" onClick={closeMobileNav}>Proyectos</a></li>
                    <li><a className="smoth-scroll" href="#contact" onClick={closeMobileNav}>Contacto</a></li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
