import clientImg1 from '../../img/client/client-1.jpg';
import clientImg2 from '../../img/client/client-2.jpg';
import clientImg3 from '../../img/client/client-3.jpg';
import clientImg4 from '../../img/client/client-4.jpg';
import clientImg5 from '../../img/client/client-5.jpg';
import clientImg6 from '../../img/client/client-6.jpg';
import clientImg7 from '../../img/client/client-7.jpg';
import clientImg8 from '../../img/client/client-8.jpg';

export const clientItems = [
  {
    img: clientImg1,
    alt: "RAC Construcciones"
  },
  {
    img: clientImg2,
    alt: "Fravega"
  },
  {
    img: clientImg3,
    alt: "Garbarino"
  },
  {
    img: clientImg4,
    alt: "Las Cortes"
  },
  {
    img: clientImg5,
    alt: "Swiss Medical"
  },
  {
    img: clientImg6,
    alt: "Tienda San Juan"
  },
  {
    img: clientImg7,
    alt: "Paseo Salta"
  },
  {
    img: clientImg8,
    alt: "Riva"
  }
];
