import portfolioImg1 from '../../img/portfolio/001.jpg';
import portfolioImg2 from '../../img/portfolio/002.jpg';
import portfolioImg3 from '../../img/portfolio/003.jpg';
import portfolioImg4 from '../../img/portfolio/004.jpg';
import portfolioImg5 from '../../img/portfolio/005.jpg';
import portfolioImg6 from '../../img/portfolio/006.jpg';
import portfolioImg7 from '../../img/portfolio/007.jpg';
import portfolioImg8 from '../../img/portfolio/008.jpg';
import portfolioImg9 from '../../img/portfolio/009.jpg';
import portfolioImg10 from '../../img/portfolio/010.jpg';
import portfolioImg11 from '../../img/portfolio/011.jpg';
import portfolioImg12 from '../../img/portfolio/012.jpg';

export const portfolioItems = [
  {
    img: portfolioImg1,
    title: "Policonsultorios fundación Leven - Fachadas, aislaciones , cielorrasos en centro de rehabilitación - 2018.",
    alt: "Policonsultorios fundación Leven - Fachadas, aislaciones , cielorrasos en centro de rehabilitación - 2018.",
    classes: "viviendas fachadas revestimientos",
  },
  {
    img: portfolioImg2,
    title: "Cielorraso PVC en salón de ventas de Tienda San Juan.",
    alt: "Cielorraso PVC en salón de ventas de Tienda San Juan.",
    classes: "cielorrasos",
  },
  {
    img: portfolioImg3,
    title: "Berlin Motors Sucursal Salta - 2015. Cielorrasos en salón de ventas.",
    alt: "Berlin Motors Sucursal Salta - 2015. Cielorrasos en salón de ventas.",
    classes: "cielorrasos",
  },
  {
    img: portfolioImg4,
    title: "Aislación y Cielorrasos acústicos en salón de fiestas - 2016.",
    alt: "Aislación y Cielorrasos acústicos en salón de fiestas - 2016.",
    classes: "cielorrasos",
  },
  {
    img: portfolioImg5,
    title: "Dirección de tránsito - Revestimientos y cielorraso en paseo Libertad - 2018.",
    alt: "Dirección de tránsito - Revestimientos y cielorraso en paseo Libertad - 2018.",
    classes: "tabiques viviendas",
  },
  {
    img: portfolioImg6,
    title: "Estructura y rigidizado de paneles, obra en Castellanos.",
    alt: "Estructura y rigidizado de paneles, obra en Castellanos.",
    classes: "viviendas tabiques",
  },
  {
    img: portfolioImg7,
    title: "Construcción vivienda en steel frame - Etapa estructura de vivienda - Praderas de San Lorenzo - 2018.",
    alt: "Construcción vivienda en steel frame - Etapa estructura de vivienda - Praderas de San Lorenzo - 2018.",
    classes: "viviendas tabiques",
  },
  {
    img: portfolioImg8,
    title: "Construcción vivienda en steel frame - Etapa de Rigidización de paneles. Praderas de San Lorenzo - 2018.",
    alt: "Construcción vivienda en steel frame - Etapa de Rigidización de paneles. Praderas de San Lorenzo - 2018.",
    classes: "viviendas revestimientos",
  },
  {
    img: portfolioImg9,
    title: "Revestimiento EIFS, vivienda en barrio privado Praderas de San Lorenzo - 2018.",
    alt: "Revestimiento EIFS, vivienda en barrio privado Praderas de San Lorenzo - 2018.",
    classes: "viviendas revestimientos",
  },
  {
    img: portfolioImg10,
    title: "Terminación gris y molduras en barrio privado El Tipal.",
    alt: "Terminación gris y molduras en barrio privado El Tipal.",
    classes: "viviendas revestimientos fachadas",
  },
  {
    img: portfolioImg11,
    title: "Etapas varias de revestimiento en viviendas de “Steel Frame” en El Tipal.",
    alt: "Etapas varias de revestimiento en viviendas de “Steel Frame” en El Tipal.",
    classes: "viviendas revestimientos fachadas",
  },
  {
    img: portfolioImg12,
    title: "Terminaciones de exteriores, vivienda en Castellanos.",
    alt: "Terminaciones de exteriores, vivienda en Castellanos.",
    classes: "viviendas revestimientos fachadas",
  },
];
