import React from 'react';

// Import video assets
import mp4Video from '../video/ojeda.mp4';
import ogvVideo from '../video/ojeda.ogv';
import webmVideo from '../video/ojeda.webm';
import videoPoster from '../video/ojeda.jpg';

const Home: React.FC = () => {
  return (
    <section id="home">
      {/* Background Video */}
      <video id="home-bg-video" poster={videoPoster} autoPlay loop muted>
        <source src={mp4Video} type="video/mp4" />
        <source src={ogvVideo} type="video/ogg" />
        <source src={webmVideo} type="video/webm" />
      </video>
      <div id="home-bg-image"></div>


      {/* Overlay */}
      <div id="home-overlay"></div>

      {/* Home Content */}
      <div id="home-content">
        <div id="home-content-inner" className="text-center">
          <div id="home-heading">
            <h1 id="home-heading-1">
              <span>A</span>lejandro <span>O</span>jeda
            </h1>
            <br />
            <h1 id="home-heading-2">Construcciones</h1>
          </div>

          <div id="home-text">
            <p>Construcción en seco para tu futuro</p>
          </div>

          <div id="home-btn">
            <a className="btn btn-general btn-home smoth-scroll" href="#about" title="Comenzar" role="button">
              Comenzar
            </a>
          </div>
        </div>
      </div>

      {/* Arrow Down */}
      <a href="#about" id="arrow-down" className="smoth-scroll">
        <i className="fa fa-angle-down smoth-scroll"></i>
      </a>
    </section>
  );
};

export default Home;
