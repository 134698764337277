import './css/app.css';
import About from './components/about';
import Header from './components/header'
import Home from './components/home';
import Statement from './components/statement';
import Teams from './components/teams';
import Services from './components/services';
import Portfolio from './components/portfolio/portfolio';
import Testimonials from './components/testimonial/testimonials';
import Stats from './components/stats/stats';
import Clients from './components/corporate-clients/clients';
import Footer from './components/footer';
import ContactV2 from './components/contact-v2/contact';


function App() {
  return (
    <>
      <Header />
      <Home />
      <About />
      <Teams />
      <Statement />
      <Services />
      <Portfolio />
      <Testimonials />
      <Stats />
      <Clients />
      <ContactV2 />
      <Footer />
    </>
  );
}

export default App;
