import React from 'react';

const Statement: React.FC = () => {
  return (
    <section id="statement">
      <div className="content-box-lg">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeIn">
              <div id="mision-statement" className="text-center">
                <h3>
                  <i className="fa fa-quote-left"></i> Ponemos a tu disposición nuestro talento y experiencia adquiridos en el trabajo diario con familias, clientes comerciales y grandes empresas constructoras, para aplicarlos exitosamente en tu próximo proyecto. <i className="fa fa-quote-right"></i>
                </h3>
                <p>- Ing. Alejandro Ojeda -</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Statement;
